import { useAppInfo } from 'hooks/useAppInfo'
import { mergeClassNames } from 'utils/utils'
import { AccountTypesEnum } from 'services/ga-debit-card/types'

import { AppBar } from '@interco/inter-ui/components/AppBar'
import { FlexColumn, FlexRow } from '@interco/inter-ui/components/Flex'
import { Text } from '@interco/inter-ui/components/Text'
import { RadioGroup } from '@interco/inter-ui/components/RadioGroup'
import { Radio } from '@interco/inter-ui/components/Radio'
import { Spacing } from '@interco/inter-ui/components/Spacing'
import { Button } from '@interco/inter-ui/components/Button'
import { Alert } from '@interco/inter-ui/components/Alert'
import { Skeleton } from '@interco/inter-ui/components/Skeleton'
import ArrowLeft from '@interco/icons/orangeds/MD/arrow-left'
import Globe from '@interco/icons/orangeds/MD/globe'
import Brasil from '@interco/icons/orangeds/MD/brasil'

import { useGaDebitCard } from './useGaDebitCard'
import { GaDebitCardInAnalysis } from './ga-debit-card-in-analysis'
import { GaDebitCardError } from './ga-debit-card-error'

const globalAccountChips = ['Cayman', 'Sem spread', 'Sem IOF']
const brazilAccountChips = ['IOF de 3,38%', 'Spread de 4%']

export const GaDebitCard = () => {
  const appInfo = useAppInfo()
  const selectedLanguage = appInfo?.language ?? 'pt-BR'

  const {
    accountSelected,
    setAccountSelected,
    handleBackButton,
    onSubmit,
    isInAnalysis,
    searchParams,
    isCreatingConfig,
    isGettingSettings,
  } = useGaDebitCard()

  const title: Record<string, string> = {
    'pt-BR': 'Cartão de débito',
  }

  if (isInAnalysis) {
    return <GaDebitCardInAnalysis handleBackButton={handleBackButton} />
  }

  if (searchParams.get('isError') === 'true') {
    return <GaDebitCardError handleBackButton={handleBackButton} />
  }

  return (
    <FlexColumn height="100vh" justifyContent="flex-start">
      {!isGettingSettings && (
        <AppBar leftIcon={<ArrowLeft width={24} height={24} onClick={handleBackButton} />}>
          {title[selectedLanguage]}
        </AppBar>
      )}
      <FlexColumn className="p-6" height="100%">
        {!isGettingSettings && (
          <FlexColumn gap="0.5rem">
            <Text variant="headline-h2" colorWeight={500} semiBold>
              Cartão de débito global
            </Text>
            <Text variant="body-3">
              Selecione abaixo de qual conta serão debitadas as transações efetuadas com o cartão:
            </Text>
          </FlexColumn>
        )}
        <Spacing mt="sm" />
        {isGettingSettings ? (
          <FlexColumn gap="1rem" width="min-content">
            <Skeleton width="100%" height="75px" />
            <Skeleton width="100%" height="75px" />
          </FlexColumn>
        ) : (
          <RadioGroup
            name="account-options"
            value={accountSelected}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              event.preventDefault()
              setAccountSelected(
                event.target.value as
                  | AccountTypesEnum.ACCOUNT_CAYMAN
                  | AccountTypesEnum.ACCOUNT_BRAZIL,
              )
            }}
            className="flex flex-col w-full space-y-6"
          >
            <FlexRow
              className={mergeClassNames(
                accountSelected === AccountTypesEnum.ACCOUNT_CAYMAN
                  ? 'border-[var(--primary400)]'
                  : 'border-[var(--gray200)]',
                'p-4 border border-solid rounded-lg',
              )}
              justifyContent="space-between"
              width="100%"
              onClick={() => setAccountSelected(AccountTypesEnum.ACCOUNT_CAYMAN)}
            >
              <FlexRow gap="0.5rem" alignItems="center">
                <Globe width={24} height={24} />
                <FlexColumn gap="0.5rem">
                  <Text variant="body-3" colorWeight={500} bold>
                    Conta Global
                  </Text>
                  <FlexRow gap="0.25rem" justifyContent="start">
                    {globalAccountChips.map((chip) => (
                      <Text
                        key={chip}
                        variant="caption-2"
                        colorWeight={500}
                        className="flex px-1 py-[2px] bg-[var(--alert100)]"
                      >
                        {chip}
                      </Text>
                    ))}
                  </FlexRow>
                </FlexColumn>
              </FlexRow>

              <Radio id="ACCOUNT_CAYMAN" value="ACCOUNT_CAYMAN" />
            </FlexRow>

            <FlexRow
              className={mergeClassNames(
                accountSelected === AccountTypesEnum.ACCOUNT_BRAZIL
                  ? 'border-[var(--primary400)]'
                  : 'border-[var(--gray200)]',
                'p-4 border border-solid rounded-lg',
              )}
              justifyContent="space-between"
              width="100%"
              onClick={() => setAccountSelected(AccountTypesEnum.ACCOUNT_BRAZIL)}
            >
              <FlexRow gap="0.5rem" alignItems="center">
                <Brasil width={24} height={24} />
                <FlexColumn gap="0.5rem">
                  <Text variant="body-3" colorWeight={500} bold>
                    Conta Brasil
                  </Text>
                  <FlexRow gap="0.25rem" justifyContent="start">
                    {brazilAccountChips.map((chip) => (
                      <Text
                        key={chip}
                        variant="caption-2"
                        colorWeight={500}
                        className="flex px-1 py-[2px] bg-[var(--alert100)]"
                      >
                        {chip}
                      </Text>
                    ))}
                  </FlexRow>
                </FlexColumn>
              </FlexRow>

              <Radio id="ACCOUNT_BRAZIL" value="ACCOUNT_BRAZIL" />
            </FlexRow>
          </RadioGroup>
        )}

        {!isGettingSettings && (
          <FlexColumn className="mt-auto" gap="1.5rem">
            <Alert type="grayscale">
              Seu cartão global é vinculado a sua Conta Cayman, mas, no momento, não suporta
              depósitos e não mantém saldo em conta. As transações que você fizer com seu cartão
              global utilizarão o saldo da sua Global Account da Inter&Co Payments ou da sua Conta
              Digital do Banco Inter no Brasil. Impostos e taxas se aplicam.
            </Alert>
            <Button
              fullWidth
              onClick={onSubmit}
              disabled={accountSelected === AccountTypesEnum.ACCOUNT_CAYMAN || isCreatingConfig}
              isLoading={isCreatingConfig}
            >
              Confirmar
            </Button>
          </FlexColumn>
        )}
      </FlexColumn>
    </FlexColumn>
  )
}
