import { Term } from 'pages/TermsAndConditions/types'

const data: Record<string, Term[]> = {
  'pt-BR': [
    {
      id: 'e-sign-consent-agreement',
      title: 'Acordo de consentimento para assinatura eletrônica',
      description:
        'O usuário concorda em receber comunicações eletrônicas da Inter&Co e utilizar assinaturas eletrônicas.',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/e-sign-consent-pt-BR.pdf',
    },
    {
      id: 'short-form',
      title: 'Inter Conta Global',
      description: 'Acesse informações sobre taxas de serviço',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/short-form-pt-BR.pdf',
    },
    {
      id: 'long-form',
      title: 'Cronograma de Taxas da Conta Global Inter',
      description: 'Acesse informações sobre taxas de serviço',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/long-form-pt-BR.pdf',
    },
    {
      id: 'user-agreement',
      title: 'Termo de Acordo do Usuário',
      description: 'Acesse o Contrato de Usuário para o uso do aplicativo e da conta',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/user-agreement_20250219-pt-BR.pdf',
    },
    {
      id: 'global-account-agreement',
      title: 'Contrato da Conta Global Inter',
      description: 'Acesse os termos de uso da conta',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/global-account-agreement_20250313-pt-BR.pdf',
    },
    {
      id: 'privacy-policy-ccpa-bipa',
      title: 'Política de Privacidade',
      description: 'Conheça nossas políticas de privacidade',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/privacy-policy-pt-BR.pdf',
    },
    {
      id: 'inter-pay-disclousures',
      title: 'Acordo do InterPay',
      description: 'Acesse os termos de uso do InterPay',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/interpay-agreement-pt-BR.pdf',
    },
    {
      id: 'security-section',
      title: 'Segurança Inter',
      description: 'Conheça nossas medidas e compromissos para fornecer um ambiente muito seguro',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/security-section-pt-BR.pdf',
    },
    {
      id: 'compliance-declaration',
      title: 'Declaração de Conformidade',
      description:
        'Acesse nossa declaração de conformidade em relação à prevenção de lavagem de dinheiro e financiamento do terrorismo',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/compliance-declaration-pt-BR.pdf',
    },
    {
      id: 'licensing-information',
      title: 'Informações de Licenciamento Estadual',
      description: 'Acesse informações sobre licenças por estado',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/licensing-information_20250219-pt-BR.pdf',
    },
    {
      id: 'fraud-prevention',
      title: 'Prevenção a Fraudes',
      description: 'Conheça informações sobre prevenção de fraudes e abuso contra idosos',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/fraud-prevention-pt-BR.pdf',
    },
    {
      id: 'compliance',
      title: 'Compliance',
      description: 'Conheça nossas políticas de conformidade',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/compliance-pt-BR.pdf',
    },
    {
      id: 'cashback-agreement',
      title: 'Acordo de Cashback',
      description: 'Acesse os termos de uso do cashback',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/cashback-agreement-pt-BR.pdf',
    },
  ],
  'en-US': [
    {
      id: 'e-sign-consent-agreement',
      title: 'E-sign consent agreement',
      description:
        'Consent to use electronic signatures and receive electronic communications from Inter&Co.',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/e-sign-consent-en-US.pdf',
    },
    {
      id: 'short-form',
      title: 'inter&co Account | Short Form Disclosure',
      description: `Access information about service fees`,
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/short-form-en-US.pdf',
    },
    {
      id: 'long-form',
      title: 'inter&co Account Fees Schedule | Long Form',
      description: `Access information about service fees`,
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/long-form-en-US.pdf',
    },
    {
      id: 'user-agreement',
      title: 'User Agreement',
      description: `Access the User Agreement of the app's and account's usage`,
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/user-agreement_20250219-en-US.pdf',
    },
    {
      id: 'global-account-agreement',
      title: 'inter&co Account Agreement',
      description: `Access the terms of account's usage`,
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/global-account-agreement_20250313-en-US.pdf',
    },
    {
      id: 'privacy-policy-ccpa-bipa',
      title: 'Privacy Policy',
      description: `Know our privacy policies`,
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/privacy-policy-en-US.pdf',
    },
    {
      id: 'inter-pay-disclousures',
      title: 'InterPay Agreement',
      description: 'Review the InterPay terms of use',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/interpay-agreement-en-US.pdf',
    },
    {
      id: 'security-section',
      title: 'Inter Security',
      description: `Know our measures and commitments to provide a very secure environment`,
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/security-section-en-US.pdf',
    },
    {
      id: 'compliance-declaration',
      title: 'Compliance Declaration',
      description: `Access our compliance statement regarding prevention of money laundering and terrorism financing`,
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/compliance-declaration-en-US.pdf',
    },
    {
      id: 'licensing-information',
      title: 'State Licensing Information',
      description: `Access information regarding licenses by state`,
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/licensing-information_20250219-en-US.pdf',
    },
    {
      id: 'fraud-prevention',
      title: 'Fraud Prevention',
      description: `Know information about fraud prevention and elder abuse`,
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/fraud-prevention-en-US.pdf',
    },
    {
      id: 'compliance',
      title: 'Compliance',
      description: `Know our compliance policies`,
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/compliance-en-US.pdf',
    },
    {
      id: 'cashback-agreement',
      title: 'Cashback Agreement',
      description: `Access the terms of cashback usage`,
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/cashback-agreement-en-US.pdf',
    },
  ],
  'es-ES': [
    {
      id: 'e-sign-consent-agreement',
      title: 'Acuerdo de consentimiento de Firma electrónica (E-Sign)',
      description:
        'Consienta a usar una firma electrónica y a recibir comunicaciones electrónicas de Inter&Co',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/e-sign-consent-es-ES.pdf',
    },
    {
      id: 'short-form',
      title: 'Inter Cuenta Global',
      description: 'Acceda a información sobre tarifas de servicio',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/short-form-es-ES.pdf',
    },
    {
      id: 'long-form',
      title: 'Programa de Tarifas de Cuenta Global Inter',
      description: 'Acceda a información sobre tarifas de servicio',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/long-form-es-ES.pdf',
    },
    {
      id: 'user-agreement',
      title: 'Acuerdo de Usuario',
      description: 'Acceda al Contrato de Usuario para el uso de la aplicación y de la cuenta',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/user-agreement_20250219-es-ES.pdf',
    },
    {
      id: 'global-account-agreement',
      title: 'Acordo de Cuenta Inter Global',
      description: 'Acceda a los términos de uso de la cuenta',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/global-account-agreement_20250313-es-ES.pdf',
    },
    {
      id: 'privacy-policy-ccpa-bipa',
      title: 'Política de Privacidad',
      description: 'Conozca nuestras políticas de privacidad',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/privacy-policy-es-ES.pdf',
    },
    {
      id: 'inter-pay-disclousures',
      title: 'Acuerdo de InterPay',
      description: 'Acceda a los términos de uso de InterPay',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/interpay-agreement-es-ES.pdf',
    },
    {
      id: 'security-section',
      title: 'Seguridad Inter',
      description: 'Conozca nuestras medidas y compromisos para brindar un ambiente muy seguro',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/security-section-es-ES.pdf',
    },
    {
      id: 'compliance-declaration',
      title: 'Declaración de Cumplimiento',
      description:
        'Acceda a nuestra declaración de cumplimiento en relación a la prevención del lavado de dinero y el financiamiento del terrorismo',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/compliance-declaration-es-ES.pdf',
    },
    {
      id: 'licensing-information',
      title: 'Información de Licencia Estatal',
      description: 'Acceda a información sobre licencias por estado',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/licensing-information_20250219-es-ES.pdf',
    },
    {
      id: 'fraud-prevention',
      title: 'Prevención del Fraude',
      description:
        'Conozca información sobre prevención de fraudes y abuso contra personas mayores',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/fraud-prevention-es-ES.pdf',
    },
    {
      id: 'compliance',
      title: 'Compliance',
      description: 'Conozca nuestras políticas de cumplimiento',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/compliance-es-ES.pdf',
    },
    {
      id: 'cashback-agreement',
      title: 'Acuerdo de Cashback',
      description: 'Acceda a los términos de uso del cashback',
      url: 'https://s3.sa-east-1.amazonaws.com/static.bancointer.com.br/globalAccount/termos/termos-webview/cashback-agreement-es-ES.pdf',
    },
  ],
}

export default data
